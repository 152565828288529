import React from "react"
import styled from "styled-components"
import Layout, { MyLocationEnum } from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import SideBannerImage from "../components/SideBanner/SideBannerImage"
import FutureDate from "../components/Future/components/FutureDate"
import { mediaMax } from "../utils/breakpoints"
import SEO, { MetaOG } from "../components/Seo"

export default ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        future {
          title
          text
        }
        allFutureDates {
          nodes {
            title
            date(formatString: "DD.MM")
            description
            link
          }
        }
        file(name: { eq: "Advania1255" }) {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalImg
            }
          }
        }
      }
    `
  )

  const seo_og: MetaOG = {
    title: "Framundan",
    description: "Viðburðir framundan",
  }

  return (
    <Layout location={location} myLocation={MyLocationEnum.Framundan}>
      <SEO
        title="Framundan"
      />
      <Container>
        <SideBannerImage image={data.file} isLocal={false} />
        <FutureContainer>
          <h1>{data.future.title}</h1>
          <p>{data.future.text}</p>

          <FutureDates>
            {data.allFutureDates.nodes.map((node, index) => (
              <FutureDate
                key={index}
                date={node.date}
                title={node.title}
                description={node.description}
                link={node.link}
              />
            ))}
          </FutureDates>
        </FutureContainer>
      </Container>
    </Layout>
  )
}
const Container = styled.div`
  display: flex;

  @media ${mediaMax.tablet} {
    flex-direction: column;
  }
`

export const FutureContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 66.66vw;
  margin-left: 33.33vw;
  padding: 0 10%;
  padding-top: 150px;

  h1 {
    font-family: "Adelle";
    font-size: max(24px, 3.333vw);
    font-weight: bold;
    color: #202020;
    margin-bottom: 30px;
  }

  p {
    width: 90%;
    font-family: "Adelle Sans";
    font-size: max(9px, 1.25vw);
    margin-bottom: 50px;
  }
  @media ${mediaMax.tablet} {
    width: 100%;
    padding-top: 60px;
    margin-left: 0;
    margin-top: 33vh;

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
    }
  }

  @media ${mediaMax.mobileL} {
    padding: 0 5%;
    padding-top: 40px;
    width: 100%;

    p {
      width: 100%;
    }
  }
`

const FutureDates = styled.div`
  margin-bottom: 30px;
`
