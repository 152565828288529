import React, { ReactNode } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { mediaMin, mediaMax } from "../../utils/breakpoints"

interface SideBannerImageProps {
  image?: any
  isLocal?: boolean
  fullscreen?: boolean
  backgroundColor?: string
}

export default ({ image, isLocal, fullscreen, backgroundColor }: SideBannerImageProps) => {
  if (isLocal) {
    return (
      <Container backgroundColor={backgroundColor}>
        <BackgroundContainer>
          {/* style={{ top: '-25px', right: '-25px', bottom: '-25px', left: '-25px', position: 'absolute' }}	 */}
          <ImageContainer className={fullscreen ? "background fullscreen" : ""}>
            <img src={image} alt="Company logo" />
          </ImageContainer>
        </BackgroundContainer>
      </Container>
    )
  }
  return (
    <Container backgroundColor={backgroundColor}>
      <BackgroundContainer>
        {/* style={{ top: '-25px', right: '-25px', bottom: '-25px', left: '-25px', position: 'absolute' }}	 */}
        <BackgroundImage
          fluid={image.childImageSharp.fluid}
          style={{ position: "absolute" }}
          durationFadeIn={200}
          className="background"
          
        />
      </BackgroundContainer>
    </Container>
  )
}

const Container = styled.div<SideBannerImageProps>`
  height: 100vh;
  width: 33.333vw;
  background-color: ${({backgroundColor}) => backgroundColor};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media ${mediaMax.tablet} {
    min-height: 33vh;
    height: 33vh;
    width: 100%;
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  overflow: hidden;
  transition: transform 2s ease;
  width: 100%;
  height: 100%;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  > img {
    width: 80vw;
    position: absolute;
    margin-top: 30px;
    transform: rotate(90deg);
  }

  &.fullscreen {
    margin-top: -15px;

    > img {
      width: 100vw;
      height: 100%;
    }
  }

  @media ${mediaMax.tablet} {
    > img {
      transform: rotate(0deg);
    }
  }
`
