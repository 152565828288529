import * as React from "react"
import styled from "styled-components"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"
import { OutboundLink } from "gatsby-plugin-google-analytics"

interface DateProp {
  date: string
  title: string
  description: string
  link: string
}

const months: { [key: string]: string } = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "Maí",
  "06": "Jún",
  "07": "Júl",
  "08": "Águ",
  "09": "Sep",
  "10": "Okt",
  "11": "Nóv",
  "12": "Des",
}

export default ({ date, title, description, link}: DateProp) => {
  const dateDay = date.substring(0, date.indexOf("."))
  const dateMonth = months[date.substring(date.length, date.indexOf(".") + 1)]
  return (
    <FutureDate href={link} target="_blank">
      <div className="date">
        <div className="dateDay">{dateDay}</div>
        <div className="dateMonth">{dateMonth}</div>
      </div>
      <div className="dateText">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </FutureDate>
  )
}

const FutureDate = styled(OutboundLink)`
  display: flex;
  margin-bottom: 40px;
  width: 80%;
  text-decoration:none;
  color:black;

  &:hover{
    
    h2{
      text-decoration:underline;
    }
  }

  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75px;
    margin-right: 24px;

    &:before {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background: linear-gradient(63.43deg, #769a30 0%, #d8ea78 100%);
      margin-bottom: 15px;
    }

    .dateDay {
      font-family: "Adelle";
      font-size: max(12px, 1.67vw);
      color: #303030;
    }

    .dateMonth {
      font-family: "Adelle Sans";
      font-size: max(8px, 0.83vw);
      color: #303030;
    }
  }
  .dateText {
    min-width: 100%;
  }
  h2 {
    font-family: "Adelle";
    font-size: max(12px, 1.67vw);
  }
  p {
    font-size: max(9px, 1.25vw);
    margin-bottom: 0;
  }

  @media ${mediaMax.tablet} {
    margin-bottom: 0;
    .date {
      .dateDay {
        font-size: 18px;
      }

      .dateMonth {
        font-size: 14px;
      }
    }
  }
`
